import React, { useState } from 'react'
import Option from './option'
import { v4 as uuid } from 'uuid'

export default function Question({ data, index, onDelete, destroy }) {
  const [options, optionsHandler] = useState(
    data?.questionOptions?.map((entry) => ({
      entry,
      uid: uuid(),
      destroy: false,
    })) ?? []
  )
  const [questionType, setQuestionType] = useState(
    data?.questionType ?? 'selection'
  )

  const addOption = () => {
    optionsHandler([...options, { entry: null, uid: uuid(), destroy: false }])
  }

  const onDeleteOption = (current) => {
    const newOptions = [...options]

    if (newOptions[current].entry == null) {
      newOptions.splice(current, 1)
    } else {
      newOptions[current].destroy = true
    }

    optionsHandler(newOptions)
  }

  if (destroy) {
    return (
      <>
        <input
          type='hidden'
          value={data.id}
          name={`quiz[questions_attributes][${index}][id]`}
          id={`quiz_questions_attributes_${index}_id`}
        />
        <input
          name={`quiz[questions_attributes][${index}][_destroy]`}
          type='hidden'
          value='1'
        />
      </>
    )
  }

  return (
    <div className='mb-3'>
      <div className='mb-1 d-flex align-items-start'>
        <span className='flex-grow-1 text optional quiz_questions_ask'>
          <label
            className='sr-only text optional'
            htmlFor={`quiz_questions_attributes_${index}_ask`}
          >
            Ask
          </label>
          <textarea
            className='form-control text optional'
            name={`quiz[questions_attributes][${index}][ask]`}
            id={`quiz_questions_attributes_${index}_ask`}
            defaultValue={data?.ask ?? ''}
          />
        </span>
        <button
          type='button'
          className='btn btn-danger ms-2'
          onClick={onDelete}
        >
          <i className='fas fa-trash'></i>
        </button>
        <input
          name={`quiz[questions_attributes][${index}][_destroy]`}
          type='hidden'
          value='0'
        />
      </div>

      <select
        className='form-select'
        style={{ maxWidth: '300px' }}
        name={`quiz[questions_attributes][${index}][question_type]`}
        id={`quiz_questions_attributes_${index}_question_type`}
        value={questionType}
        onChange={(e) => setQuestionType(e.target.value)}
      >
        <option value='selection'>Seleção</option>
        <option value='text'>Discursivo</option>
      </select>

      <div className='form-check'>
        <input
          type='hidden'
          value='0'
          name={`quiz[questions_attributes][${index}][optional]`}
          id={`quiz_questions_attributes_${index}_optional`}
        />
        <input
          className='form-check-input'
          type='checkbox'
          name={`quiz[questions_attributes][${index}][optional]`}
          defaultChecked={data?.optional}
          value='1'
          id={`quiz_questions_attributes_${index}_optional`}
        />
        <label
          className='form-check-label'
          htmlFor={`quiz_questions_attributes_${index}_optional`}
        >
          Opcional?
        </label>
      </div>

      {data && (
        <input
          type='hidden'
          value={data.id}
          name={`quiz[questions_attributes][${index}][id]`}
          id={`quiz_questions_attributes_${index}_id`}
        />
      )}

      {questionType === 'selection' ? (
        <div className='px-3'>
          {options.map((option, optionIndex) => (
            <Option
              key={option.uid}
              data={option.entry}
              destroy={option.destroy}
              questionIndex={index}
              index={optionIndex}
              onDelete={() => onDeleteOption(optionIndex)}
            />
          ))}
          <button
            type='button'
            className='btn btn-success d-block mx-auto'
            onClick={addOption}
          >
            <i className='fas fa-plus-square' />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
