import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import Question from './question'

export default function Form({ initialQuestions }) {
  const [questions, questionsHandler] = useState(
    initialQuestions.map((entry) => ({ entry, uid: uuid(), destroy: false }))
  )

  const addQuestion = () => {
    questionsHandler([
      ...questions,
      { entry: null, uid: uuid(), destroy: false },
    ])
  }

  const onDelete = (index) => {
    const newQuestions = [...questions]

    if (newQuestions[index].entry == null) {
      newQuestions.splice(index, 1)
    } else {
      newQuestions[index].destroy = true
    }

    questionsHandler(newQuestions)
  }

  return (
    <>
      {questions.map((question, index) => (
        <Question
          key={question.uid}
          data={question.entry}
          destroy={question.destroy}
          index={index}
          onDelete={() => onDelete(index)}
        />
      ))}

      <button
        type='button'
        className='btn btn-primary btn-lg d-block mx-auto'
        onClick={addQuestion}
      >
        <i className='fas fa-plus-square me-2' />
        Adicionar Questão
      </button>
    </>
  )
}
