import React from 'react'

export default function Question({
  data,
  questionIndex,
  index,
  onDelete,
  destroy,
}) {
  const fieldName = `quiz[questions_attributes][${questionIndex}][question_options_attributes][${index}]`
  const fieldId = `quiz_questions_attributes_${questionIndex}_question_options_attributes_${index}`
  if (destroy) {
    return (
      <>
        <input
          type='hidden'
          value={data?.id}
          name={fieldName + '[id]'}
          id={fieldId + '_id'}
        />
        <input
          name={fieldName + '[_destroy]'}
          id={fieldId + '__destroy'}
          type='hidden'
          value='1'
        />
      </>
    )
  }

  return (
    <div className='mb-1 d-flex align-items-start'>
      <span className='flex-grow-1 text optional quiz_questions_ask'>
        <label className='sr-only text optional' htmlFor={fieldId + `_text`}>
          Ask
        </label>
        <input
          className='form-control text optional'
          name={fieldName + `[text]`}
          id={fieldId + `_text`}
          defaultValue={data?.text ?? ''}
        />
      </span>
      <button
        type='button'
        className='btn btn-danger btn-sm ms-2'
        onClick={onDelete}
      >
        <i className='fas fa-trash'></i>
      </button>
      <input
        name={fieldName + `[_destroy]`}
        id={fieldId + '__destroy'}
        type='hidden'
        value='0'
      />
      {data && (
        <input
          type='hidden'
          value={data.id}
          name={fieldName + `[id]`}
          id={fieldId + `_id`}
        />
      )}
    </div>
  )
}
